import React from "react"
import ScrollMagic from "scrollmagic"
import { TweenMax, Power0, Linear } from "gsap/TweenMax"
import Slider from "react-slick"
import styled from "@emotion/styled"

import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"

import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import Section from "../components/Section"
import ContactForm from "../components/ContactForm"
import Spacer from "../components/Spacer"
import OrderNow from "../components/OrderNow"
import P from "../components/type/P"
import A from "../components/type/A"
import Logo from "../components/Logo"
import Fancy from "../components/type/Fancy"

import star from "../images/star.svg"
import arrowDown from "../images/arrow-down.svg"

import { colorGold, colorGreen, breakpoints } from "../utils"

const Slide = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.img});
  img {
    width: 100%;
    height: 100%;
  }
`

const SliderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`

const PinTrigger = styled.div`
  height: 1px;
  width: 100%;
  border-top: ${props => (props.borderTop ? "1px solid yellow" : "")};
  border-bottom: ${props => (props.borderBottom ? "1px solid red" : "")};
`

const StarContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 35px;
    height: 35px;
  }
`

const LandingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  text-align: center;
`

const LandingBackground = styled.div`
  width: 500px;
  height: 500px;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 99, 55, 0.5) 0%,
    rgba(0, 99, 55, 0) 80%
  );
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TutorialContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  opacity: ${props => props.opacity};
  transition: opacity 0.5s;
  background-color: rgba(0, 99, 55, 0.4);
`

const TutorialContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 60px;

    @media (max-width: ${breakpoints.mobile}px) {
      width: 40px;
    }
  }
`
const MapDetails = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 170px;
  border: 1px solid ${colorGold};
  padding: 16px;
  background-color: ${colorGreen};

  @media (max-width: ${breakpoints.mobile}px) {
    width: 75%;
    margin-left: 0;
    transform: translate(-50%, -25px);
    margin-top: 50px;
    padding: 16px;
  }
`

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  pauseOnHover: false,
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
      showTutorial: false,
    }

    this.tutorialTimer = 0
  }

  onScroll = () => {
    this.setState({
      hasScrolled: true,
      showTutorial: false,
    })
  }

  createMagic = () => {
    new ScrollMagic.Scene({
      triggerElement: "#logoTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight,
    })
      .setTween(
        TweenMax.from("#logoScene", 0.5, { y: 400, ease: Linear.easeOut })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#logoTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight * 8,
    })
      .setPin("#logoScene", { pushFollowers: false })
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight / 2,
    })
      .setPin("#landingScene")
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.from("#landingSceneBackground", 0.5, {
          opacity: 0,
          ease: Linear.easeOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.from("#landingScene0", 0.5, {
          y: "200px",
          x: 0,
          opacity: 0,
          ease: Linear.easeOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.from("#landingScene1", 0.5, {
          y: "200px",
          x: 0,
          opacity: 0,
          ease: Linear.easeOut,
          delay: 0.1,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      offset: document.documentElement.clientHeight / 2,
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.to("#landingSceneBackground", 0.5, {
          opacity: 0,
          ease: Linear.easeOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      offset: document.documentElement.clientHeight / 2,
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.to("#landingScene0", 0.5, {
          x: 0,
          opacity: 0,
          ease: Linear.easeOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#landingTrigger",
      triggerHook: "onLeave",
      offset: document.documentElement.clientHeight / 2,
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.to("#landingScene1", 0.5, {
          x: 0,
          opacity: 0,
          ease: Linear.easeOut,
          delay: 0.2,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#mapTrigger",
      triggerHook: "onEnter",
      duration: document.documentElement.clientHeight / 4,
    })
      .setTween(
        TweenMax.from("#mapScene", 0.5, {
          y: "100px",
          ease: Power0.easeOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#mapTrigger",
      triggerHook: "onLeave",
      duration: document.documentElement.clientHeight / 2,
    })
      .setPin("#mapScene")
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#mapTrigger",
      triggerHook: "onEnter",
      offset: document.documentElement.clientHeight * 0.3,
      duration: document.documentElement.clientHeight * 0.7,
    })
      .setTween(
        TweenMax.from("#starScene", 0.5, {
          css: { scale: 30, opacity: 0, rotation: 180 },
          ease: Power0.easeInOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#mapTrigger",
      triggerHook: "onEnter",
      offset: document.documentElement.clientHeight * 0.7,
      duration: document.documentElement.clientHeight * 0.3,
    })
      .setTween(
        TweenMax.from("#mapDetailScene", 0.5, {
          css: { opacity: 0, y: 100 },
          ease: Power0.easeInOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#mapTrigger",
      triggerHook: "onLeave",
      offset: document.documentElement.clientHeight,
      duration: document.documentElement.clientHeight,
    })
      .setTween(
        TweenMax.to("#mapScene", 0.5, {
          css: { opacity: 0, y: -100 },
          ease: Power0.easeInOut,
        })
      )
      .addTo(this.controller)

    new ScrollMagic.Scene({
      triggerElement: "#contactTrigger",
      triggerHook: "onEnter",
      duration: document.documentElement.clientHeight,
    })
      .setTween(
        TweenMax.from("#contactScene", 0.5, {
          css: { opacity: 0.5, y: document.documentElement.clientHeight },
          ease: Power0.easeInOut,
        })
      )
      .addTo(this.controller)
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll)
    clearTimeout(this.tutorialTimer)
    this.controller = this.controller.destroy(true)
  }

  componentDidMount() {
    this.tutorialTimer = setTimeout(() => {
      if (!this.state.hasScrolled) {
        this.setState({ showTutorial: true })
      }
    }, 5000)

    document.addEventListener("scroll", this.onScroll)

    this.controller = new ScrollMagic.Controller()

    this.createMagic()
  }

  render() {
    return (
      <Layout>
        <PinTrigger id="carouselTrigger" />
        <SliderContainer id="carouselScene">
          <Slider {...settings}>
            <Slide img="images/img-carousel-1.jpg" />
            <Slide img="images/img-carousel-2.jpg" />
            <Slide img="images/img-carousel-3.jpg" />
            <Slide img="images/img-carousel-4.jpg" />
            <Slide img="images/img-carousel-5.jpg" />
            <Slide img="images/img-carousel-6.jpg" />
            <Slide img="images/img-carousel-7.jpg" />
          </Slider>
        </SliderContainer>
        <TutorialContainer opacity={this.state.showTutorial ? "1" : "0"}>
          <TutorialContent>
            <Fancy fontSize="60px" fontSizeMobile="40px">
              More this way
            </Fancy>
            <img src={arrowDown} alt="arrow down" />
          </TutorialContent>
        </TutorialContainer>
        <Spacer height="100vh" />
        <PinTrigger id="logoTrigger" />
        <Logo id="logoScene" />
        <Spacer height="100vh" />
        <PinTrigger id="landingTrigger" />
        <Section id="landingScene">
          <LandingContainer>
            <LandingBackground id="landingSceneBackground" />
            <Fancy
              id="landingScene0"
              fontSize="60px"
              fontSizeMobile="40px"
              lineHeightMobile="40px"
            >
              Welcome to 20th Street!
            </Fancy>

            <Fancy
              id="landingScene1"
              fontSize="30px"
              fontSizeMobile="20px"
              lineHeightMobile="20px"
            >
              Naturally leavened vegan pizza
            </Fancy>
          </LandingContainer>
        </Section>
        {/* <Spacer height="250vh" /> */}
        <PinTrigger id="mapTrigger" />
        <Section id="mapScene">
          {/* <Map isMarkerShown={false} /> */}
          <Slide img="images/img-map.gif" />
          <StarContainer id="starScene">
            <img src={star} alt="star" />
          </StarContainer>
          <MapDetails id="mapDetailScene">
            <div>
              <Fancy color={`${colorGold}`} fontSize="30px">
                20th Street Pizza
              </Fancy>
              <Spacer height="5px" />
              <P color={`${colorGold}`}>108 South 20th Street</P>
              <P color={`${colorGold}`}>Philadelphia, PA 19103</P>
              <A href="https://goo.gl/maps/MpCYNopXU1Zow34q9" target="_blank">
                + Google Maps
              </A>
            </div>
            <div style={{ marginTop: "8px" }}>
              <P color={`${colorGold}`}>
                <b>Hours</b>
              </P>
              <P color={`${colorGold}`}>Tuesday - Sunday: 12pm - 9pm</P>
              <P color={`${colorGold}`}>Closed Monday</P>
            </div>
          </MapDetails>
        </Section>
        <Spacer height="50vh" />
        <PinTrigger id="contactTrigger" />
        <Section id="contactScene">
          <ContactForm />
        </Section>
        <OrderNow />
      </Layout>
    )
  }
}

export default IndexPage
