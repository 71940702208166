import React, { Component } from "react"
import styled from "@emotion/styled"

import { spacingDefault } from "../../../utils"

import Layout from "../../../components/Layout"
import Logo from "../../../components/Logo"
import P from "../../../components/type/P"
import A from "../../../components/type/A"

const CopyContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: ${spacingDefault};
  box-sizing: border-box;
`

class index extends Component {
  componentDidMount() {
    window.location.replace(
      "https://www.livebetterphl.com/restaurant-detail/20th%20st%20pizza"
    )
  }
  render() {
    return (
      <Layout>
        <Logo id="logoScene" />
        <CopyContainer>
          <P>
            If you are not redirected automatically, please{" "}
            <A
              href="https://www.livebetterphl.com/restaurant-detail/20th%20st%20pizza"
              target="_blank"
            >
              click here
            </A>{" "}
            or navigate to
            https://livebetterphl.web.app/restaurant-detail/20th%20st%20pizza{" "}
          </P>
        </CopyContainer>
      </Layout>
    )
  }
}

export default index
