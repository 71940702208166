import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: ${props => (props.position ? props.position : "relative")};
  overflow: hidden;
`
class Section extends React.Component {
  render() {
    const { children, position, id } = this.props
    return (
      <Container id={id} position={position}>
        {children}
      </Container>
    )
  }
}

export default Section
