import React from "react"
import styled from "@emotion/styled"

import { breakpoints } from "../../utils"

const Fancy = styled.h1`
  font-family: blackbird-script;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
  line-height: ${props =>
    props.lineHeight || props.fontSize
      ? props.lineHeight || props.fontSize
      : "16px"};
  color: ${props => (props.color ? props.color : "#FFF")};
  display: ${props => (props.display ? props.display : "block")};
  margin-bottom: 0;

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: ${props =>
      props.fontSizeMobile || props.fontSize
        ? props.fontSizeMobile || props.fontSize
        : "16px"};
    line-height: ${props =>
      props.lineHeightMobile || props.fontSizeMobile
        ? props.lineHeightMobile || props.fontSizeMobile
        : "16px"};
  }
`

const A = ({ children, ...rest }) => {
  return <Fancy {...rest}>{children}</Fancy>
}

export default A
