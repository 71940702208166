import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import {
  spacingDefault,
  colorGreen,
  colorGreenDark,
  colorGold,
  animDurationDefault,
  breakpoints,
} from "../utils"
import A from "./type/A"

const containerCSS = css`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  color: #fff;
  font-family: Arial;
  text-decoration: none;
  background-color: ${colorGreen};
  padding: 5px;
  z-index: 9999;
  transition: background-color ${animDurationDefault};

  /* &:before,
  &:after {
    content: "";
    position: absolute;

    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translateY(-4px);
  }
  &:after {
    transform: translateY(4px);
  } */

  &:hover {
    background-color: ${colorGreenDark};
  }
`

const linkCSS = css`
  color: #fff;
  text-decoration: none;
`

const Border = styled.div`
  border: 5px solid ${colorGold};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const ButtonCopy = styled.span`
  padding: ${spacingDefault};
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  line-height: 16px;

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 14px;
    padding: 0.5rem;
  }
`

const OrderNow = () => {
  return (
    <div css={containerCSS}>
      <Border>
        <div>
          <A
            css={linkCSS}
            href="https://order.20thstreet.pizza"
            target="_blank"
          >
            <ButtonCopy>ORDER NOW</ButtonCopy>
          </A>
          <Link css={linkCSS} to="/order/giftcards">
            <ButtonCopy> GIFT CARDS</ButtonCopy>
          </Link>
        </div>
      </Border>
    </div>
  )
}

export default OrderNow
