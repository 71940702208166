import React from "react"
import axios from "axios"
import DOMPurify from "dompurify"
import styled from "@emotion/styled"
import { Form } from "react-final-form"
import InputField from "./InputField"
import Fancy from "./type/Fancy"
import P from "./type/P"

import instaLogo from "../images/logo-insta.svg"

import {
  colorGold,
  colorGreen,
  spacingDefault,
  borderRadiusDefault,
  animDurationDefault,
  breakpoints,
} from "../utils"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${colorGold};
  box-sizing: border-box;
  padding-top: 250px;
  display: flex;
  justify-content: center;
  font-family: Arial, sans-serif;

  @media (max-width: ${breakpoints.mobile}px) {
    padding-top: 160px;
  }
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 0 ${spacingDefault};
`

const CopyContainer = styled.div`
  margin-bottom: ${spacingDefault};
  display: flex;
  align-items: center;
  flex-direction: column;
`

const InstaLink = styled.a`
  text-decoration: none;
`

const InstaContainer = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-left: ${spacingDefault};
  img {
    width: 100%;
  }
`

const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
`

const FieldContainer = styled.div`
  width: ${props => props.width};
`

const Button = styled.button`
  background-color: ${colorGreen};
  padding: ${spacingDefault};
  color: ${colorGold};
  border-radius: ${borderRadiusDefault};
  border: none;
  cursor: pointer;
  transition: background-color ${animDurationDefault},
    color ${animDurationDefault};

  &:hover {
    background-color: #fff;
    color: ${colorGreen};
  }

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 14px;
    padding: 8px;
  }
`

const ThankYouContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorGold};
  position: absolute;
  top: 0;
  left: 0;
  display: ${props => (props.show ? "block" : "none")};
`

const ThankYouContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${spacingDefault};
  border: 1px solid ${colorGreen};
  border-radius: 4px;
`

const nameValidate = value =>
  !value || !/^[a-zA-Z ]+$/.test(value) ? "Please enter valid name." : null

const emailValidate = value =>
  !value ||
  !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  )
    ? "Please enter valid email address."
    : null

const messageValidate = value =>
  !value || !/[A-Za-z0-9 _.,!"'/$]*/.test(value)
    ? "Please enter valid message."
    : null

const mustBeNumber = value =>
  Number(value) !== 24 ? "Nope, try again" : undefined

class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showThankYou: false,
    }
  }

  onSubmit = async values => {
    const payload = Object.keys(values)
      .map(key => key + "=" + DOMPurify.sanitize(values[key]))
      .join("&")

    axios
      .post("/freecontactformprocess.php", payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        this.setState({
          showThankYou: true,
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  render() {
    return (
      <Container>
        <FormContainer>
          <CopyContainer>
            <InstaLink
              href="https://www.instagram.com/20thstreetpizza/"
              target="_blank"
            >
              <Fancy fontSize="40px" color={colorGreen} display="flex">
                Check us out on{" "}
                <InstaContainer>
                  <img src={instaLogo} alt="insta logo" />
                </InstaContainer>
              </Fancy>
            </InstaLink>
            <P color={colorGreen}>...or connect with us below!</P>
          </CopyContainer>
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} name="form">
                <FieldsContainer>
                  <FieldContainer width="48%">
                    <InputField
                      name="Full_Name"
                      field="Full_Name"
                      id="name"
                      placeholder="Name"
                      validate={nameValidate}
                      type="input"
                    />
                  </FieldContainer>
                  <FieldContainer width="48%">
                    <InputField
                      name="Email_Address"
                      field="email"
                      id="email"
                      placeholder="Email address"
                      validate={emailValidate}
                      type="input"
                    />
                  </FieldContainer>
                  <FieldContainer width="100%" style={{ marginTop: "4%" }}>
                    <InputField
                      name="Your_Message"
                      field="message"
                      id="message"
                      placeholder="Message"
                      validate={messageValidate}
                      type="textarea"
                    />
                  </FieldContainer>
                  <FieldContainer width="20%" style={{ marginTop: "4%" }}>
                    <InputField
                      name="AntiSpam"
                      field="antispam"
                      id="antispam"
                      placeholder="answer"
                      label="Whats 12 * 2"
                      validate={mustBeNumber}
                      type="input"
                    />
                    <Button
                      type="submit"
                      disabled={submitting}
                      style={{
                        marginTop: "4%",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      Submit
                    </Button>
                  </FieldContainer>
                  <br />
                </FieldsContainer>
              </form>
            )}
          />
          <ThankYouContainer show={this.state.showThankYou}>
            <ThankYouContent>
              <Fancy fontSize="30px" color={colorGreen}>
                We got it!
              </Fancy>
              <P color={colorGreen}>
                Thanks for reaching out, we'll get back to you as soon as we
                can.
              </P>
              <Button
                onClick={() => {
                  this.setState({ showThankYou: false })
                }}
              >
                Close
              </Button>
            </ThankYouContent>
          </ThankYouContainer>
        </FormContainer>
      </Container>
    )
  }
}

export default ContactForm
