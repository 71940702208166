import React, { Component } from "react"
import styled from "@emotion/styled"

import { spacingDefault } from "../../utils"

import Layout from "../../components/Layout"
import Logo from "../../components/Logo"
import P from "../../components/type/P"
import A from "../../components/type/A"

const CopyContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: ${spacingDefault};
  box-sizing: border-box;
`

class index extends Component {
  componentDidMount() {
    window.location.replace(
      "https://www.toasttab.com/20th-street-pizza/v2/online-order#!/"
    )
  }
  render() {
    return (
      <Layout>
        <Logo id="logoScene" />
        <CopyContainer>
          <P>
            If you are not redirected automatically, please{" "}
            <A
              href="https://www.toasttab.com/20th-street-pizza/v2/online-order#!/"
              target="_blank"
            >
              click here
            </A>{" "}
            or navigate to
            https://www.toasttab.com/20th-street-pizza/v2/online-order#!/{" "}
          </P>
        </CopyContainer>
      </Layout>
    )
  }
}

export default index
