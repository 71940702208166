export const animDurationDefault = ".3s"

export const borderRadiusDefault = "4px"

export const colorGold = "#c7bd5e"
export const colorGreen = "#006337"
export const colorGreenDark = "#001e00"

export const spacingDefault = "1rem"

export const breakpoints = {
  mobile: 767,
}
