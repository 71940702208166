import React from "react"
import styled from "@emotion/styled"

import { breakpoints } from "../../utils"

const PEl = styled.p`
  font-size: 16px;
  color: ${props => (props.color ? props.color : "#FFF")};
  margin-bottom: 0;
  font-family: sans-serif;
  line-height: 18px;

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 13px;
    line-height: 15px;
  }
`

const P = ({ children, ...rest }) => {
  return <PEl {...rest}>{children}</PEl>
}

export default P
