import React from "react"
import styled from "@emotion/styled"

const AEl = styled.a`
  font-size: 16px;
  color: ${props => (props.color ? props.color : "#FFF")};
  margin-bottom: 0;

  &:hover {
    color: ${props =>
      props.hoverColor || props.color
        ? props.hoverColor || props.color
        : "#FFF"};
  }
`

const A = ({ children, ...rest }) => {
  return <AEl {...rest}>{children}</AEl>
}

export default A
