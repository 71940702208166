import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  width: 100%;
  height: ${props => (props.height ? props.height : "100vh")};
`

/* class Spacer extends Component { */
const Spacer = ({ height }) => {
  return <Container height={height} />
}

export default Spacer
