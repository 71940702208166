// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-delivery-index-js": () => import("./../src/pages/order/delivery/index.js" /* webpackChunkName: "component---src-pages-order-delivery-index-js" */),
  "component---src-pages-order-giftcards-index-js": () => import("./../src/pages/order/giftcards/index.js" /* webpackChunkName: "component---src-pages-order-giftcards-index-js" */),
  "component---src-pages-order-index-js": () => import("./../src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-order-pickup-index-js": () => import("./../src/pages/order/pickup/index.js" /* webpackChunkName: "component---src-pages-order-pickup-index-js" */)
}

