import React from "react"
import styled from "@emotion/styled"
import { breakpoints } from "../utils"

import logo from "../images/logo-20th-st.svg"

const LogoContainer = styled.div`
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: center;

  img {
    /* max-width: 300px; */
    width: 300px;
    height: 150px;

    @media (max-width: ${breakpoints.mobile}px) {
      width: 200px;
      height: 100px;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    padding: 20px;
  }
`

const Logo = ({ ...rest }) => {
  return (
    <LogoContainer {...rest}>
      <img src={logo} alt="20th street logo" />
    </LogoContainer>
  )
}

export default Logo
