import React from "react"
import { Field } from "react-final-form"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import {
  colorGreen,
  spacingDefault,
  borderRadiusDefault,
  breakpoints,
} from "../utils"

const fieldStyles = css`
  border: 1px solid ${colorGreen};
  background-color: transparent;
  color: ${colorGreen};
  outline: none;
  border-radius: ${borderRadiusDefault};
  padding: ${spacingDefault};
  width: 100%;

  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: ${breakpoints.mobile}px) {
    padding: 8px;
    font-size: 14px;
  }
`

const FieldContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`

const Label = styled.label`
  color: ${colorGreen};
  font-size: 14px;
`

const Error = styled.span`
  color: ${colorGreen};
  font-size: 12px;
  position: absolute;
  left: 3px;
  bottom: -20px;
`

const InputField = props => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ input, meta }) => (
        <FieldContainer>
          {props.label && <Label>{props.label}</Label>}
          {props.type && props.type === "textarea" && (
            <textarea
              {...input}
              type="text"
              placeholder={props.placeholder}
              css={fieldStyles}
              style={{ minHeight: "200px" }}
            />
          )}
          {props.type && props.type === "input" && (
            <input
              {...input}
              type="text"
              placeholder={props.placeholder}
              css={fieldStyles}
            />
          )}
          {meta.error && meta.touched && <Error>{meta.error}</Error>}
        </FieldContainer>
      )}
    </Field>
  )
}

// <Field validate={props.validate} field={props.field}>
//   {fieldApi => {
//     // Remember to pull off everything you dont want ending up on the <input>
//     // thats why we pull off onChange, onBlur, and field
//     // Note, the ...rest is important because it allows you to pass any
//     // additional fields to the internal <input>.
//     const { onChange, onBlur, placeholder } = props

//     const {
//       value,
//       error,
//       warning,
//       success,
//       setValue,
//       setTouched,
//     } = fieldApi

//     return (
//       <div>
//         <input
//           placeholder={placeholder}
//           value={value || ""}
//           onChange={e => {
//             setValue(e.target.value)
//             if (onChange) {
//               onChange(e.target.value, e)
//             }
//           }}
//           onBlur={e => {
//             setTouched()
//             if (onBlur) {
//               onBlur(e)
//             }
//           }}
//           css={fieldStyles}
//         />

//         {error ? <Message color="red" message={error} /> : null}
//         {!error && warning ? (
//           <Message color="orange" message={warning} />
//         ) : null}
//         {!error && !warning && success ? (
//           <Message color="green" message={success} />
//         ) : null}
//       </div>
//     )
//   }}
// </Field>
export default InputField
